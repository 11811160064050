import lib from 'lib';

var K = {
  defaultFontSize: 11 / 3,
  //css
  margin: 5,
  spacing: 10,
  shadow: {boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)'},
  paneWidth: 250,
  borderRadius: 5,
  defaultIconSize: {width: 20, height: 20},
  defaultFontSize: 4.5,
  //WARNING gets set to libre franklin for HB
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  fonts: {
    label: {fontSize: '0.9em', letterSpacing: '1px', fontWeight: 'normal', opacity: 0.5, textTransform: 'uppercase'},
    header: {fontSize: '1.2rem', letterSpacing: '1px', fontWeight: 'bold'}
  },
  grayBorder: '1px solid rgba(0, 0, 0, 0.1)',
  uploadedFilesUrlPrefix: 'https://s3-us-west-2.amazonaws.com/henrybuilt-uploaded-files',
  cdnUrlPrefix: 'https://s3-us-west-2.amazonaws.com/henrybuilt-cdn',

  colors: {
    bad: '#ce6f6f',
    good: '#80b963',
    selected: 'rgb(145, 203, 234)',
    stencil: 'rgba(234, 200, 157)',
    invalid: '#ED7B84',
    datums: '#ab9002',
    customDimensions: '#fa8546',
    elevationStroke: '#479F6A',
    lighting: '#FFD800',
    hatches: '#888888'
  },
  round: value => {
    if (typeof(value) === 'object') {
      return lib.object.round(value, {toNearest: K.minPrecision});
    }
    else {
      return lib.number.round(value, {toNearest: K.minPrecision});
    }
  },
  appearancePropsByTheme: () => ({
    stroke: {light: '#333333', dark: 'white'}
  }),
};

export default K;
