import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store } from 'redux/index.js';
import MediaIndexPage from 'pages/media-index-page';

global.NODE_ENV = process.env.NODE_ENV;

function App() {
  return (
    <Provider store={store}>
      <div style={{height: '100%'}}>
        <Router>
          <Routes>
            <Route exact path='/media' element={<MediaIndexPage />}/>
            <Route render={() => <div>404</div>}/>
          </Routes>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
