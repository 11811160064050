import lib from 'lib';

import { createStore as _createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { singularize } from 'inflection';

import _ from 'lodash';

import thunk from 'redux-thunk';
import { connect as _connect } from 'react-redux';

function reducerAndActionsFor(superType, payloadGetters) {
  var actionsData = _.mapValues(payloadGetters, (getPayload, subType) => {
    return {
      getPayload,
      type: `${superType}.${subType}`,
      isAsync: true//getPayload.constructor === (async function () {}).constructor
    };
  });

  var actions = _.mapValues(actionsData, ({type, getPayload, isAsync}) => {
    // return ({dispatch, isAsync}) => {
    return (props) => {
      return (dispatch, getState) => {
        var prevState = _.get(getState(), superType);

        if (isAsync) {
          (async () => {
            var _getPayload = async () => await getPayload({getState, prevState, [superType]: prevState, ...props});

            dispatch({type: `${type}.begin`, payload: {...prevState, isLoading: true}});

            try {
              var payload = await _getPayload();

              dispatch({type: `${type}.success`, payload: {isLoading: false, ...payload}});
            }
            catch (error) {
              console.error(error); //eslint-disable-line

              dispatch({type: `${type}.failure`, payload: {isLoading: false}});

              throw error;
            }
          })();
        }
        else {
          var _getPayload = () => getPayload({getState, prevState, [superType]: prevState, ...props});

          dispatch({type, payload: _getPayload()});
        }
      };
    };
    // };
  });

  var reducer = (state = {}, action) => {
    var {type: t} = action;

    _.forEach(actionsData, ({type}) => {
      if (t === `${type}.begin` || t === `${type}.success` || t === `${type}.failure` || t === type) {
        state = {...action.payload};
      }
    });

    return state;
  };

  return {actions, reducer};
}

function connect({mapState, mapDispatch}) {
  var mapStateToProps = (state, ownProps) => {
    var props = _.pick(state, ['session']);

    if (mapState) {
      if (_.isFunction(mapState)) {
        props = {...props, ...mapState(state, ownProps)};
      }
      else {
        _.forEach(mapState, statePath => {
          props = {...props, [statePath]: _.get(state, statePath)};
        });
      }
    }

    return props;
  };

  var mapDispatchToProps = (dispatch) => {
    var props = {};

    if (mapDispatch) {
      if (_.isFunction(mapDispatch)) {
        props = {...props, ...mapDispatch(dispatch)};
      }
      else {
        props = {...props, ..._.mapValues(mapDispatch, action => {
          return (...args) => dispatch(action(...args));
        })};
      }
    }

    return props;
  };

  return _connect(mapStateToProps, mapDispatchToProps);
}

export default function createGenericStore({reducers, initialState = {}} = {}) {
  var rootReducer = combineReducers({...reducers});

  var store = _createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
  );

  return store;
}

var resourcesReducerAndActionsFor = ({resourcesData, api}) => {
  var reducerAndActionsByResource = {};
  _.forEach(resourcesData, ({indexedFieldKeys = [], apiResourceKey: apiPKey}, pKey) => {
    var sKey = singularize(pKey);

    if (!apiPKey) apiPKey = pKey;

    var apiSKey = singularize(apiPKey);//sKey;

    var actionKeyFor = (action, key) => _.camelCase(`${action}-${_.kebabCase(key)}`);

    var stateFor = (state, {prevState, oldResources, resources, actionKey}) => {
      ///HINT i.e. resources.stories.byFieldKeyIndex.parentFieldId.16 = {2: {id: 2}, 3: {id: 3}}
      //WARNING performance is sensitive here because of how many loops that are happening on each common action
      //WARNING there are two levels of indexes here - the key fieldKey, and the possible field values, i.e. {parentFieldId: 16}, {parentFieldId: 17} etc
      _.forEach(indexedFieldKeys, fieldKey => {
        var nextIndex = {..._.get(prevState, `byFieldKeyIndex.${fieldKey}`)};
        if ((oldResources && oldResources.length) || (resources && resources.length)) {
          if (_.includes(['destroy', 'update', 'track'], actionKey)) {
            _.forEach(oldResources, oldResource => {
              var prevFieldIndex = nextIndex[oldResource[fieldKey]];

              if (prevFieldIndex && prevFieldIndex[`${oldResource.id}`]) {
                var nextFieldIndex = nextIndex[oldResource[fieldKey]] = {...prevFieldIndex};

                delete nextFieldIndex[`${oldResource.id}`];
              }
            });
          }

          if (_.includes(['track', 'create', 'update'], actionKey)) {
            _.forEach(resources, resource => {
              var fieldIndex = nextIndex[resource[fieldKey]] = {...nextIndex[resource[fieldKey]]};

              //WARNING intentionally not spreading {...resource} to reduce memory
              fieldIndex[`${resource.id}`] = resource;
            });
          }
        }

        _.set(state, `byFieldKeyIndex.${fieldKey}`, nextIndex);
      });

      state.id = _.uniqueId();

      return state;
    };

    reducerAndActionsByResource[pKey] = reducerAndActionsFor(`resources.${pKey}`, {
      [actionKeyFor('track', pKey)]: async ({params, reset = false, getState, ...args}) => { //effectively a "push"
        var resources = args[pKey];
        var prevState = reset ? {byId: {}, byFieldKeyIndex: {}} : _.get(getState(), `resources.${pKey}`, {});
        var byId = {...prevState.byId, ..._.keyBy(resources, 'id')};
        var oldResources = reset ? [] : _.values(_.pick(prevState.byId, _.map(resources, ({id}) => `${id}`)));

        return stateFor({byId}, {prevState, oldResources, resources, actionKey: 'track'});
      },
      [actionKeyFor('create', sKey)]: async ({props, resource, files, hitApi = true, getState}) => {
        if (hitApi) resource = await api.create(apiSKey, {props: _.omit(props, ['id'])}, {files});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var byId = {...prevState.byId, [resource.id]: resource};

        return stateFor({byId}, {prevState, resources: [resource], actionKey: 'create'});
      },
      [actionKeyFor('create', pKey)]: async ({propsSets, resources, files, hitApi = true, getState}) => {
        if (hitApi) resources = await api.create(apiPKey, _.map(propsSets, props => _.omit(props, ['id'])), {files});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var byId = {...prevState.byId, ..._.keyBy(resources, 'id')};

        return stateFor({byId}, {prevState, resources, actionKey: 'create'});
      },
      [actionKeyFor('update', sKey)]: async ({id, props, hitApi = true, getState}) => {
        props = _.omit(props, ['created', 'lastUpdated']);

        if (hitApi) api.update(apiSKey, {where: {id}, props});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var oldResources = prevState.byId;
        var oldResource = oldResources[id];
        var resource = {...oldResource, ...props};
        var byId = {...oldResources, [id]: resource};

        return stateFor({byId}, {prevState, oldResources: [oldResource], resources: [resource], actionKey: 'update'});
      },
      [actionKeyFor('update', pKey)]: async ({ids, props, updates, files, hitApi = true, getState}) => {
        props = _.omit(props, ['created', 'lastUpdated']);
        updates = _.map(updates, update => {
          return {...update, props: _.omit(update.props, ['created', 'lastUpdated'])};
        }) || [];

        if (hitApi) api.update(apiPKey, updates ? updates : {where: {id: ids}, props}, {files});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var allOldResources = prevState.byId;
        var byId = {...allOldResources};
        var oldResources = [], resources = [];

        if (ids && props) updates = _.map(ids, id => ({where: {id}, props}));

        _.forEach(updates, ({props, where}) => {
          var oldResource = allOldResources[where.id];
          var resource = byId[where.id] = {...oldResource, ...props};

          oldResources.push(oldResource);
          resources.push(resource);
        });

        return stateFor({byId}, {prevState, oldResources, resources, actionKey: 'update'});
      },
      [actionKeyFor('destroy', sKey)]: async ({id, hitApi = true, getState}) => {
        if (hitApi) api.destroy(apiSKey, {where: {id}});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var allOldResources = prevState.byId;
        var byId = _.omit(allOldResources, [id + '']);

        return stateFor({byId}, {prevState, oldResources: [allOldResources[`${id}`]], actionKey: 'destroy'});
      },
      [actionKeyFor('destroy', pKey)]: async ({ids, hitApi = true, getState}) => {
        if (hitApi) api.destroy(apiPKey, {where: {id: ids}});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var allOldResources = prevState.byId;
        var stringIds = _.map(ids, id => `${id}`);
        var byId = _.omit(allOldResources, stringIds);

        return stateFor({byId}, {prevState, oldResources: _.values(_.pick(allOldResources, stringIds)), actionKey: 'destroy'});
      },
      [actionKeyFor('modify', pKey)]: async ({creations = [], updates = [], destructions = [], tracks = [], hitApi = true, getState}) => {
        let resources = [];
        let oldResources = [];
        let createdResources = [];
        let prevState = _.get(getState(), `resources.${pKey}`, {});
        let byId = prevState.byId;

        if (hitApi) {
          let requestResources = {
            create: {
              [apiPKey]: _.map(creations, props => _.omit(props, ['id']))
            },
            update: {
              [apiPKey]: updates
            },
            destroy: {
              [apiPKey]: [{where: {id: destructions}}]
            }
          };

          //HINT only care about awaiting if we're creating things
          if (creations.length > 0) {
            let response = await api.request({uri: 'resources', body: {resources: requestResources}});

            createdResources = _.get(response, `data.resources.create.${[apiPKey]}`) || [];
          }
          else {
            api.request({uri: 'resources', body: {resources: requestResources}});
          }

        }

        //</ track and create
        byId = {...byId, ..._.keyBy(tracks, 'id'), ..._.keyBy(createdResources, 'id')};
        oldResources.push(..._.values(_.pick(prevState.byId, _.map(tracks, ({id}) => `${id}`))) || []);
        resources.push(...createdResources, ...tracks);
        ///>

        //</ update
        _.forEach(updates, ({props, where}) => {
          var oldResource = _.find(resources, {id: where.id}) || prevState.byId[where.id];
          var resource = byId[where.id] = {...oldResource, ...props};

          oldResources.push(oldResource);
          resources.push(resource);
        });
        ///>

        //</destroy
        if (destructions.length > 0) {
          let stringIds = _.map(destructions, id => `${id}`);
          byId = _.omit(byId, stringIds);

          oldResources.push(..._.values(_.pick(prevState.byId, stringIds)));
          resources = _.reject(resources, resource => _.includes(destructions, resource.id));
        }
        ///>
        var newState = stateFor({byId}, {prevState, oldResources, resources, actionKey: 'update'});

        return newState;
      }
    });
  });

  var reducer = combineReducers(_.mapValues(reducerAndActionsByResource, 'reducer'));
  var actions = _.mapValues(reducerAndActionsByResource, 'actions');

  return {reducer, actions};
};

//TODO add correct resourcesData for client-site
var {actions: resourceActions, reducer: resourcesReducer} = resourcesReducerAndActionsFor({
  resourcesData: {
    media: {},
    tags: {},
    tagCategories: {}
  },
  api: lib.api
});

var store = createGenericStore({
  reducers: {resources: resourcesReducer},
  initialState: {}
});

export {
  store, resourceActions, connect
};
