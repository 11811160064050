import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import K from 'k';
import { AddButton, Popup, HudElement, Checkbox, Button, SearchBar, Dropdown,
  CheckableTag, Tag, AppHeader, Icon, Image, Upload, OverlayUpload
} from '@henrybuilt/react-lib'
import InfiniteScroll from 'react-infinite-scroll-component';

import hbLogo from '../assets/hb-logo-light.png';
import xBlackIcon from '../assets/x-black.png';
import editIcon from '../assets/edit-icon.png';
import destroyIcon from '../assets/trash-icon-black.png';

import { connect, resourceActions } from 'redux/index.js';
import lib from 'lib';

const MediaIndexPage = (props) => {
  const {tags, tagCategories} = props;
  const [filters, setFilters] = useState({});
  const [media, setMedia] = useState([]);
  const [showingMediaPopup, setShowingMediaPopup] = useState(false);
  const [activeMediaIds, setActiveMediaIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(async () => {
    const requestedTags = await lib.api.get('tags');
    const requestedTagCategories = await lib.api.get('tagCategories');

    props.trackTags({tags: requestedTags, reset: true});
    props.trackTagCategories({tagCategories: requestedTagCategories, reset: true});
  }, []);

  const requestMedia = async ({filtersUpdated=false}={}) => {
    var where = {};

    if (filters.type !== '*') where.type = filters.type;

    // if (filters.public !== '*') where.public = filters.public;

    if (filters.inRorschachSet) where.inRorschachSet = filters.inRorschachSet;
    if (filters.inProposalSet) where.inProposalSet = filters.inProposalSet;

    if (_.includes(filters.company, 'vp')) where.vpCompatible = 1;
    if (_.includes(filters.company, 'hb')) where.hbCompatible = 1;

    // if (filters.product !== '*') where.productId = filters.product;

    // if (filters.bottomLevelProductCategory !== '*') where.bottomLevelProductCategoryId = filters.bottomLevelProductCategory;
    // if (filters.middleLevelProductCategory !== '*') where.middleLevelProductCategoryId = filters.middleLevelProductCategory;

    // if (filters.material !== '*') where.materialId = filters.material;
    // if (filters.materialPreset !== '*') where.materialPresetId = filters.materialPreset;
    // if (filters.productTag !== '*') where.productTagId = filters.productTag;
    // if (filters.container !== '*') where.containerId = filters.container;
    // if (filters.documentSubtopic !== '*') where.documentSubtopicId = filters.documentSubtopic;

    // if (filters.subjectType !== '*') where.subjectType = filters.subjectType;

    // if (filters.clientProject !== '*') where.clientProjectId = filters.clientProject;

    // if (filters.primaryObjects === 'exclude') where.topLevelProductCategoryId = null;

    if (filters.showInPortfolio !== '*') where.showInPortfolio = filters.showInPortfolio;

    var order = [{field: 'id', direction: 'desc'}];

    // if (filters.sortByRank) order.unshift({field: 'rank', direction: filters.sortByRank});

    const currentlyActiveTagIds = _.flatMap(_.filter(filters, (filter, filterKey) => {
      return !_.isNaN(_.toNumber(filterKey));
    }));

    if (currentlyActiveTagIds.length) where.tagIds = currentlyActiveTagIds;

    let {data} = await lib.api.request({uri: 'media/get', body: {
      where, order,
      page: {
        number: filtersUpdated ? 1 : (pageNumber + 1),
        count: 20
      }
    }});

    var newlyRequestedMedia = data.media;

    const updatedMedia = filtersUpdated ? newlyRequestedMedia : [...media, ...newlyRequestedMedia];
    setHasMore(newlyRequestedMedia.length < 20 ? false : true);
    setMedia(updatedMedia);
    setPageNumber(filtersUpdated ? 1 : (pageNumber + 1));
  }

  useEffect(async () => {
    await requestMedia({filtersUpdated: true});
  }, [filters]);

  const handleAddButtonClick = (option) => {
    if (option.key === 'image') {

    }
    else if (option.key === 'tag') {

    }
  }

  const updateActiveMediaIds = ({medium, forcePush}) => {
    let newMediaIds;

    if (forcePush) {
      newMediaIds = _.includes(activeMediaIds, medium.id) ? activeMediaIds : [...activeMediaIds, medium.id];
    }
    else {
      newMediaIds = _.includes(activeMediaIds, medium.id) ? _.reject(activeMediaIds, mediumId => mediumId === medium.id) : [...activeMediaIds, medium.id];
    }

    setActiveMediaIds(newMediaIds);
  }

  const handleBatchStart = async (args) => {
    setIsLoading(true);

    var newMedia = [];

    await lib.async.forEach(args, async (fileData, index) => {
      var {id} = await lib.api.create('medium', {props: {type: 'image', inRorschachSet: 0, inProposalSet: 0}}, {files: [fileData.file]});

      var newMedium = await lib.api.get('medium', {where: {id}});
      newMedia.push(newMedium);
    });

    var newMediaProps = {};

    if (filters.inRorschachSet && filters.inRorschachSet.length === 1) newMediaProps.inRorschachSet = filters.inRorschachSet[0];
    if (filters.inProposalSet && filters.inProposalSet.length === 1) newMediaProps.inProposalSet = filters.inProposalSet[0];

    if (_.includes(filters.company, 'vp')) newMediaProps.vpCompatible = 1;
    if (_.includes(filters.company, 'hb')) newMediaProps.hbCompatible = 1;

    var currentlyActiveTagIds = _.flatMap(_.filter(filters, (filter, filterKey) => {
      return !_.isNaN(_.toNumber(filterKey));
    }));

    if (currentlyActiveTagIds.length) newMediaProps.tagIds = _.map(currentlyActiveTagIds, tagId => ({actionKey: 'create', tagId}));

    if (!_.isEmpty(newMediaProps)) {
      var {data} = await lib.api.request({uri: 'media/update', body: {where: {id: _.map(newMedia, 'id')},
        props: newMediaProps
      }});

      newMedia = data.media;
    }

    props.trackMedia({media: [...newMedia, ...media], reset: true});

    setMedia([...newMedia, ...media]);
    setActiveMediaIds(_.map(newMedia, 'id'));
    setIsLoading(false);
  }

  const handleMediumClick = (medium) => {
    setActiveMediaIds([medium.id]);

    //HINT decided to make click open only that medium, rather than all selected medium
    // updateActiveMediaIds({medium, forcePush: true});

    setShowingMediaPopup(true);
  }

  const handleMediumSelectClick = (medium) => {
    updateActiveMediaIds({medium});
  }

  const handleFilterChange = ({key, value}) => {
    let updatedFilters = _.cloneDeep(filters);

    if (updatedFilters[key]) {
      if (_.includes(updatedFilters[key], value)) {
        updatedFilters[key] = _.reject(updatedFilters[key], currentValue => currentValue === value);

        if (updatedFilters[key].length === 0) delete updatedFilters[key];
      }
      else {
        updatedFilters[key].push(value);
      }
    }
    else {
      updatedFilters[key] = [value];
    }

    setFilters(updatedFilters);
  }

  const handleMediaUpdate = async ({props}) => {
    var {data} = await lib.api.request({uri: 'media/update', body: {where: {id: activeMediaIds},
      props
    }});

    var newlyUpdatedMedia = data.media;

    let updatedMedia = _.cloneDeep(media);

    _.forEach(newlyUpdatedMedia, medium => {
      updatedMedia[_.findIndex(updatedMedia, {id: medium.id})] = medium;
    });

    setMedia(updatedMedia);
  }

  const handleTagChange = async ({actionKey, tagId}) => {
    await handleMediaUpdate({props: {tagIds: [{actionKey, tagId}]}});
  }

  const deselectMedia = () => setActiveMediaIds([]);
  const deselectFilters = () => setFilters({});
  const destroyMedia = async () => {
    var haveConfirmed = confirm(`Are you sure you want to delete ${activeMediaIds.length} Media?`);

    if (haveConfirmed) {
      await lib.api.destroy('media', {where: {id: activeMediaIds}});

      setActiveMediaIds([]);
      setMedia(_.reject(_.clone(media), medium => _.includes(activeMediaIds, medium.id)));
    }
  }

  const handlePopupClose = () => {
    setShowingMediaPopup(false);
    //TODO might not want to deselect all
    deselectMedia();
  }

  const margin = '6rem';

  const filtersData = _.map([
    {key: 'type', label: 'Type',
      options: [{label: 'Image', value: 'image'}, {label: 'Vertical Image', value: 'vImage'}, {label: 'Video', value: 'video'}]
    },
    {key: 'company', label: 'Company', options: [{label: 'Henrybuilt', value: 'hb'}, {label: 'Space Theory', value: 'vp'}]},
    {key: 'inRorschachSet', label: 'Rorschach Test', options: [{label: 'Yes', value: 1}, {label: 'Awaiting Approval', value: 2}, {label: 'No', value: 0}]},
    {key: 'inProposalSet', label: 'Proposal', options: [{label: 'Yes', value: 1}, {label: 'Awaiting Approval', value: 2}, {label: 'No', value: 0}]},
    {key: 'showInPortfolio', label: 'Show in Portfolio', options: [{label: 'Yes', value: true}, {label: 'No', value: false}]},
    ..._.map(tagCategories, tagCategory => {
      return {
        key: `${tagCategory.id}`,
        label: tagCategory.title,
        options: _.map(_.filter(tags, {tagCategoryId: tagCategory.id}), tag => {
          return {label: tag.aliasesByContext.media, value: tag.id};
        })
      }
    })
  ], filterData => {
    return {
      ...filterData,
      activeOptions: filters[filterData.key]
    }
  });

  let firstMedium, activeMedia, activeTagIds, inRorschachSet, inProposalSet;

  if (showingMediaPopup && (activeMediaIds && activeMediaIds.length > 0)) {
    activeMedia = _.filter(media, medium => {
      return _.includes(activeMediaIds, medium.id);
    });
    firstMedium = _.find(media, {id: activeMediaIds[0]});
    activeTagIds = _.intersection(..._.map(activeMedia, 'tagIds'));
    inRorschachSet = _.get(_.intersection(..._.map(activeMedia, medium => [medium.inRorschachSet])), '[0]', 0);
    inProposalSet = _.get(_.intersection(..._.map(activeMedia, medium => [medium.inProposalSet])), '[0]', 0);
  }

  return (
    <>
      <div style={{overflow: 'hidden', height: '100%'}}>
        <div>
          <AppHeader logo={hbLogo} items={[<div key={0} style={{marginLeft: '1rem'}}>Media Library</div>]}/>
          <div style={{display: 'flex', height: 40, marginLeft: margin, marginRight: margin, marginTop: '3rem'}}>
            <SearchBar placeholder='Search...'
              wrapperStyle={{
                marginRight: K.spacing,
                height: 40,
                flexGrow: 1,
                zIndex: 2
              }}
              style={{
                paddingLeft: K.spacing,
                borderRadius: 5,
                border: '1px solid gray',
              }}
              filters={filtersData}
              onFilterChange={handleFilterChange}
            />
            <div style={{width: 100, zIndex: 2}}>
              <AddButton
                onClick={handleAddButtonClick}
                options={[
                  {value: 'image', manuallyHandleClick: true, label: <Upload
                    multiple contentStyle={{alignItems: 'flex-start'}}
                    onBatchStart={handleBatchStart}
                  >Media</Upload>},
                  // {value: 'tag', label: 'Tag'}
                ]}
              />
            </div>
          </div>
          <div style={{marginLeft: margin, marginTop: K.spacing, display: 'flex'}}>
            {!_.isEmpty(filters) && (
              <Button style={{cursor: 'pointer', width: 36, height: 36, justifyContent: 'center', alignItems: 'center', borderRadius: '75px', marginRight: K.spacing}} onClick={deselectFilters} icon={<Icon style={{...K.defaultIconSize}}src={xBlackIcon}/>}/>
            )}
            {!_.isEmpty(filters) && (
              _.map(filters, (filterData, filterKey) => {
                var filter = _.find(filtersData, {key: filterKey});

                return _.map(filterData, activeFilterOption => {
                  return(
                    <Tag
                      style={{marginRight: 1}}
                      onClose={() => handleFilterChange({key: filter.key, value: activeFilterOption})}
                      closable={true}
                      key={`${filter.key}-${activeFilterOption}`}>
                      <span>{filter.label}: {_.find(filter.options, {value: activeFilterOption}).label}</span>
                    </Tag>
                  );
                });
              })
            )}
          </div>
        </div>
        <div style={{position: 'relative', height: '70%', marginTop: '3rem'}}>
          {media.length > 0 && (<Checkbox checked={activeMediaIds.length === media.length} onChange={(value) => setActiveMediaIds(value ? _.map(media, 'id') : [])} style={{borderColor: activeMediaIds.length === media.length ? 'transparent' : 'black'}} containerStyle={{position: 'absolute', left: margin, top: K.spacing, marginLeft: -K.spacing * 2 - 16, zIndex: 5}}/>)}
          {isLoading && (
            <div style={{position: 'fixed', height: '100%', width: '100%', zIndex: Number.MAX_SAFE_INTEGER, backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
              <div style={{width: '60%', position: 'relative', left: '20%', top: '30%', backgroundColor: '#d1d5db', borderRadius: '9999px', height: '0.5rem', alignSelf: 'center', overflow: 'hidden'}}>
                <div style={{backgroundColor: '#3b82f6',borderRadius: '9999px',position: 'absolute', bottom: 0, top: 0, width: '50%',
                  height: '0.5rem', animationDuration: '2s',animationIterationCount: 'infinite', animationName: 'indeterminate-progress-bar'
                }}/>
              </div>
            </div>
          )}
          <OverlayUpload onBatchStart={handleBatchStart}/>
          <div id={'IndexPageMediaScrollContainer'} style={{height: '100%', paddingLeft: margin, paddingRight: margin, overflow: 'auto', display: 'flex', flex: 'wrap', flexWrap: 'wrap', flex: 1}}>
            <InfiniteScroll
              dataLength={media.length}
              next={requestMedia}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget={'IndexPageMediaScrollContainer'}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>{`End of Media - ${media.length} result${media.length !== 1 ? 's' : ''}`}</b>
                </p>
              }
            >
              {_.map(media, medium => (
                <div key={medium.id}  style={{display: 'inline-block', width: 'calc(17rem + 30px)', height: 'calc(17rem + 30px)', paddingRight: 30, paddingBottom: 30, position: 'relative'}}>
                  <Checkbox style={{cursor: 'pointer', position: 'absolute', top: K.spacing, left: K.spacing, zIndex: 1}} onChange={() => handleMediumSelectClick(medium)} checked={_.includes(activeMediaIds, medium.id)}/>
                  {medium.type === 'video' ? (
                    <video onClick={() => handleMediumClick(medium)} controls style={{cursor: 'pointer', backgroundColor: 'gray', height: '17rem', width: '17rem', marginRight: 30, marginBottom: 30, position: 'absolute', left: 0, top: 0}} autoPlay={false}>
                      <source src={medium.url} type="video/mp4"></source>
                    </video>
                  ) : (
                    <div onClick={() => handleMediumClick(medium)} style={{cursor: 'pointer', backgroundColor: 'gray', backgroundImage: `url(${medium.url})`, zIndex: 0,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      height: '17rem',
                      width: '17rem',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                    }}/>
                  )}
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {showingMediaPopup && (activeMediaIds && activeMediaIds.length > 0) && (
        <Popup onClose={handlePopupClose} placement={'right'} visible={true} closable={false} style={{zIndex: 3}} contentStyle={{justifyContent: 'start', width: '40%', padding: 0}} type={'pane'}>
            {/* <div style={{height: '45%', width: '100%', position: 'relative'}}>
              {firstMedium.type === 'video' ? (
                <video controls style={{backgroundColor: 'gray', width: '100%', height: '100%', position: 'absolute', left: 0, top: 0}} autoPlay={false}>
                  <source src={firstMedium.url} type="video/mp4"></source>
                </video>
              ) : (
                <div style={{backgroundColor: 'gray', backgroundImage: `url(${firstMedium.url})`, zIndex: 0,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                }}/>
              )}
            </div> */}
            <div style={{padding: K.spacing * 2, flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
              <div style={{display: 'flex'}}>
                <span style={{fontWeight: 'bold', marginRight: K.spacing}}>Part of Rorschach Test</span>
                <Dropdown
                  value={inRorschachSet}
                  onChange={(value) => handleMediaUpdate({props: {inRorschachSet: value}})}
                  options={[{label: 'Yes', value: 1}, {label: 'Awaiting Approval', value: 2}, {label: 'No', value: 0}]}
                />
              </div>
              <div style={{display: 'flex', marginTop: K.spacing * 2}}>
                <span style={{fontWeight: 'bold', marginRight: K.spacing}}>Part of Proposal Set</span>
                <Dropdown
                  value={inProposalSet}
                  onChange={(value) => handleMediaUpdate({props: {inProposalSet: value}})}
                  options={[{label: 'Yes', value: 1}, {label: 'Awaiting Approval', value: 2}, {label: 'No', value: 0}]}
                />
              </div>
              <div style={{marginTop: K.spacing * 2, borderTop: '1px solid #ccc'}}></div>
              <div style={{display: 'flex', marginTop: K.spacing * 2, flexGrow: 1, flexWrap: 'wrap', alignContent: 'flex-start'}}>
                {_.map(tagCategories, (tagCategory) => {
                  var tagCategoryTags = _.filter(tags, {tagCategoryId: tagCategory.id});
                  var activeTagCategoryTags = _.filter(tagCategoryTags, tag => {
                    return _.includes(activeTagIds, tag.id);
                  });

                  return (
                    <div key={tagCategory.id} style={{width: '30%', marginBottom: K.spacing}}>
                      <Dropdown
                        collapseOnChange
                        right
                        icon={<Icon style={{transform: 'rotate(45deg)', marginLeft: K.spacing, width: 12.35, height: 12.35}}src={xBlackIcon}/>}
                        value={-1}
                        label={tagCategory.title}
                        options={_.map(tagCategoryTags, tag => ({value: tag.id, label: tag.aliasesByContext.media}))}
                        onChange={(tagId) => handleTagChange({actionKey: 'create', tagId})}
                      />
                      {_.map(activeTagCategoryTags, (tag, index) => {
                        return (
                          <Tag
                            style={{marginBottom: 1, ...(index === 0 ? {marginTop: K.spacing} : {})}}
                            onClose={() => handleTagChange({actionKey: 'destroy', tagId: tag.id})}
                            closable={true}
                            key={`${tagCategory.id}-${tag.id}`}>
                            <span>{tag.aliasesByContext.media}</span>
                          </Tag>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <div style={{display: 'flex'}}>{`You are tagging ${activeMediaIds.length} ${activeMediaIds.length > 1 ? 'images' : 'image'}`}</div>
            </div>
        </Popup>
      )}
      {!showingMediaPopup && (activeMediaIds && activeMediaIds.length > 0) && (
        <div style={{display: 'flex', flexDirection: 'row-reverse', position: 'absolute', marginBottom: K.spacing * 2, bottom: 0, zIndex: 3, width: '100%'}}>
          <Button style={{cursor: 'pointer', width: 40, height: 40, justifyContent: 'center', alignItems: 'center', borderRadius: '75px', marginRight: K.spacing * 2}} onClick={() => setShowingMediaPopup(true)} icon={<Icon style={{...K.defaultIconSize}}src={editIcon}/>}/>
          <Button style={{cursor: 'pointer', width: 40, height: 40, justifyContent: 'center', alignItems: 'center', borderRadius: '75px', marginRight: K.spacing, backgroundColor: '#ce6f6f'}} onClick={destroyMedia} icon={<Icon style={{...K.defaultIconSize}}src={destroyIcon}/>}/>
          <Button style={{cursor: 'pointer', width: 40, height: 40, justifyContent: 'center', alignItems: 'center', borderRadius: '75px', marginRight: K.spacing}} onClick={deselectMedia} icon={<Icon style={{...K.defaultIconSize}}src={xBlackIcon}/>}/>
        </div>
      )}
    </>
  );
};

export default connect({
  mapState: state => {
    return {
      tags: _.values(state.resources.tags.byId),
      tagCategories: _.values(state.resources.tagCategories.byId)
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.media, ['trackMedia']),
    ..._.pick(resourceActions.tags, ['trackTags']),
    ..._.pick(resourceActions.tagCategories, ['trackTagCategories']),
  }
})(MediaIndexPage);
